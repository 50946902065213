import { Dispatch, SetStateAction, useContext, useState } from "react";
import { CustomRaisedButton } from "../../../../../../Components/CustomComponents/CustomButtons";
import AddIcon from "@mui/icons-material/Add";
import MobileViewContext from "../../../../../../Navigation/MobileViewContext";
import CustomPaperTable from "../../../../../../Components/Table/CustomPaperTable";
import _ from "lodash";
import uuid from "uuid-js";
import CustomDialog from "../../../../../../Components/CustomComponents/CustomDialog";
import SingleLectureTime from "./SingleLectureTime";
import { LectureTimeField } from "../../../../../../Store/Appearance/RequiredFields/FieldNames";
import { calculateDuration } from "../../../../../../Util/TimeUtil";
import { getRequiredFieldsByStatus } from "../../../../RequiredFields/RequiredFieldsByStatus";
import { useVioletApi } from "../../../../../../Auth/useFetchWithMsal";
import CopyOfferLinkButton from "./CopyOfferLinkButton";
import { IStringObject } from "../../../../../../Interfaces/IStringObject";
import {
  OrderLecture,
  OrderStatusEnum,
  Organization,
} from "../../../../../../generated/Api";

export default function LectureTimesTable(props: {
  orderId: number;
  orderLectures: OrderLecture[];
  organization: Organization;
  orderStatus: OrderStatusEnum;
  setOrderLectures: Dispatch<SetStateAction<OrderLecture[]>>;
}) {
  const requiredFields = getRequiredFieldsByStatus(props.orderStatus);
  const [mobileView] = useContext(MobileViewContext);
  const { callApi } = useVioletApi();
  const [editingLectureIndex, setEditingLectureIndex] = useState(-1);
  const orderLectures = props.orderLectures.map((x, index) => ({
    ...x,
    index: index,
    i: index + 1,
    type: x.isOnline ? "מקוון" : "פרונטלי",
    approved: x.approvalTime && "✅",
  }));

  const addLectureTime = () => {
    const lengthBefore = props.orderLectures.length;
    props.setOrderLectures((lectures) => [
      ...lectures,
      {
        offerToken: uuid.create(4).toString(),
      } as OrderLecture,
    ]);
    setEditingLectureIndex(lengthBefore);
  };

  const onDelete = (rowIndex: number) => {
    props.setOrderLectures((lectures) =>
      lectures.filter((_, index) => index !== rowIndex),
    );
  };

  const onChangeField =
    (key: LectureTimeField) => (value: string | boolean | undefined) => {
      const lectures = _.clone(props.orderLectures);
      const lectureTime = lectures[editingLectureIndex];
      // @ts-ignore
      lectureTime[key] = value;
      lectureTime.duration = calculateDuration(lectureTime);
      props.setOrderLectures(lectures);
    };

  const removeOfferApproval = async (orderId: number, lectureId: number) => {
    callApi((api) =>
      api.offers.removeOfferApproval({ orderId, lectureId }).then(() => {
        onChangeField(LectureTimeField.approvalTime)(undefined);
      }),
    );
  };

  const tableHeaders: IStringObject[] = [
    { i: "מזהה" },
    { type: "סוג" },
    { date: "תאריך" },
    { topic: "נושא" },
    { startTime: "התחלה" },
    { endTime: "סיום" },
    { duration: "משך" },
  ];
  if (!mobileView) {
    tableHeaders.push({ tie: "עניבה" });
    tableHeaders.push({ approved: "אושר" });
    tableHeaders.push({ copyLink: "קישור" });
    tableHeaders.push({ delete: "מחק" });
  }

  return (
    <>
      <CustomPaperTable
        title="הרצאות"
        beforeTable={
          mobileView ? null : (
            <div>
              <CustomRaisedButton
                label="הוסף הרצאה חדשה"
                onClick={addLectureTime}
                icon={<AddIcon />}
                variant="outlined"
              />
            </div>
          )
        }
        tableHeaders={tableHeaders}
        elements={orderLectures}
        onEditButton={(lecture) =>
          !mobileView && setEditingLectureIndex(lecture.index)
        }
        onDeleteButton={onDelete}
        requiredFields={requiredFields.internalOrderLectureTime}
        renderCell={(headerKey, lectureTime) => {
          if (headerKey !== "copyLink") {
            return null;
          }

          return (
            <CopyOfferLinkButton
              orderId={lectureTime.orderId}
              lectureTime={lectureTime}
              returnNullIfDisabled={true}
            />
          );
        }}
      />
      <CustomDialog
        title="עריכת הרצאה"
        open={editingLectureIndex >= 0}
        onRequestClose={() => setEditingLectureIndex(-1)}
      >
        {editingLectureIndex >= 0 && (
          <SingleLectureTime
            lectureTimeIndex={editingLectureIndex}
            lectureTime={props.orderLectures[editingLectureIndex]}
            onChangeField={onChangeField}
            requiredFields={
              props.orderLectures[editingLectureIndex].isOnline
                ? requiredFields.onlineCourseLectureTime
                : requiredFields.internalOrderLectureTime
            }
            organization={props.organization}
            removeOfferApproval={(orderId, lectureId) =>
              removeOfferApproval(orderId, lectureId)
            }
          />
        )}
      </CustomDialog>
    </>
  );
}
